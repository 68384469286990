.ui.search .prompt {
  border-radius: 0rem;
  width: 300px;
  height: 35px;
}

.ui.search > .results {
  overflow-y: auto;
  max-height: 500px;
}

.ui.menu {
  font-size: medium;
  height: min-content;
  border-radius: 0px !important;
}

#wrap {
  overflow-wrap: break-word;
}

.ag-theme-balham {
  background-color: transparent;
  margin-top: 10px;
  margin-left: 100px;
  margin-right: 100px;
}

.ag-paging-row-summary-panel {
  text-align: left;
}

.large.text {
  font-size: 16px;
}

.custom-message {
  color: #333 !important;
}

.menu .item{
  padding: 0.8rem 0.4rem!important;
}
.search > .input {
  width: 200px !important;
}


/* Sector Performance Tables */
.combined-sector-table {
  margin: 0;
}

.sector-performance-table {
  margin: 0;
  table-layout: fixed;
}

.compact-header {
  padding: 0.3em !important;
  font-size: 0.85em;
}

.sector-name {
  line-height: 1.2;
  font-weight: bold;
}

.stock-symbol-cell {
  padding: 0.4em !important;
  cursor: pointer;
  font-weight: bold;
}

.positive-change {
  color: #21ba45;
  font-weight: bold;
  padding: 0.4em !important;
}

.negative-change {
  color: #db2828;
  font-weight: bold;
  padding: 0.4em !important;
}

.vertical-label-cell {
  padding: 0 !important;
  width: 2em;
}

.vertical-label {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-align: center;
  font-weight: bold;
  padding: 1em 0.3em;
  white-space: nowrap;
}

.vertical-label.gainers {
  color: #21ba45;
}

.vertical-label.losers {
  color: #db2828;
}

/* Mobile Styles */
.mobile-sector-container {
  overflow-x: auto;
}

.mobile-sector-section {
  margin-bottom: 2em;
}

@media only screen and (max-width: 767px) {
  .sector-performance-table {
    font-size: 0.85em;
  }
  
  .compact-header {
    font-size: 0.75em;
    padding: 0.2em !important;
  }
  
  .stock-symbol-cell, 
  .positive-change, 
  .negative-change {
    padding: 0.3em !important;
  }
}

.sector-performance {
  margin: 1em 0;
}

.stock-card {
  cursor: pointer;
  transition: transform 0.2s;
  margin: 0.5em 0 !important;
}

.stock-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1) !important;
}

.stock-card .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stock-card .meta {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui.tab {
  padding: 1em 0;
}

@media only screen and (max-width: 767px) {
  .stock-card {
    min-height: auto;
  }
  
  .stock-card .header {
    font-size: 1em;
  }
}

/* Card container */
.responsive-chart-card {
  max-width: 100%;
  margin: 0 auto;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1) !important;
  border-radius: 8px !important;
}

/* Header styling */
.chart-header {
  padding-bottom: 1rem !important;
  font-size: 1.2rem !important;
}

/* Chart container */
.chart-container {
  position: relative;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 20px;
}

/* Chart plot styling */
.chart-plot {
  display: block;
  margin: 0 auto;
  width: 100% !important;
  min-width: 300px; /* Minimum width for readability */
  height: 250px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .responsive-chart-card {
    border-radius: 0 !important;
    margin: 0 -1rem !important; /* Full width on mobile */
    width: calc(100% + 2rem) !important; /* Compensate for negative margin */
  }
  
  .chart-header {
    font-size: 1rem !important;
  }
  
  .chart-plot {
    height: 200px;
  }
}

/* Very small devices */
@media (max-width: 480px) {
  .chart-plot {
    min-width: 280px;
    height: 180px;
  }
}

